import React from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { withRouter } from "react-router-dom";
import { Breadcrumb, Container, Row } from "react-bootstrap";
import ProductSearch from "../../components/ProductSearch";

/**
 * Show a typeahead component for finding rows
 * @component
 * @category Scenes
 * @subcategory Brands
 */
class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [],
            query: "",
        };
    }

    handleChange = (row) => {
        this.props.history.push(`/product/${row[0].id}`);
    };

    render() {
        return (
            <Container className="pt-4">
                <Breadcrumb className="dd">
                    <Breadcrumb.Item>Product</Breadcrumb.Item>
                    <Breadcrumb.Item active>Search</Breadcrumb.Item>
                </Breadcrumb>
                <Row>
                    {" "}
                    <ProductSearch onChange={this.handleChange} />
                </Row>
            </Container>
        );
    }
}

export default withRouter(Search);
