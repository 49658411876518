import axios from "axios";

import Config from "./Config";
import Memory from "./MemoryService";
import APIHelper from "./APIHelper";

let cancels = {};
const ManufacturerService = {
    typeahead(search, brands = []) {
        if (typeof cancels['typeahead'] !== 'undefined') {
            cancels['typeahead'].cancel("Operation canceled due to new request.");
        }
        cancels['typeahead'] = axios.CancelToken.source();
        const brandIds = brands.length > 0 ? brands : undefined;
        return axios
            .post(Config.API_URL + "manufacturer/search", {
                name: search,
                brands: brandIds
            }, {
                cancelToken: cancels['typeahead'].token
            })
            .then(APIHelper.returnItemsOrEmptyArray)
            .catch((error) => {
                if (typeof error.message !== "undefined" && /Operation canceled/.test(error.message)) {
                    return null;
                }
                return [];
            });
    },
    /**
     * Retrieves and stores the Manufacturer name in memory for rendering the Breadcrumbs of /manufacturer
     * @param {int} id
     * @returns
     */
    breadcrumb(id) {
        return new Promise((resolve, reject) => {
            if (Memory.Has('manufacturer/' + id)) {
                resolve(Memory.Get('manufacturer/' + id));
            } else {
                axios
                    .get(Config.API_URL + "manufacturer/" + id + "/name")
                    .then((response) => {
                        if(APIHelper.checkStatus(response)) {
                            Memory.Set('manufacturer/' + id, response.data.name);
                            resolve(response.data.name);
                        }
                        reject();
                    })
                    .catch((error) => {
                        reject();
                    });
            }
        });
    },
    all() {
        return axios
            .get(Config.API_URL + "manufacturer")
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    },
    getTrackedCountries(id) {
        return axios
            .get(Config.API_URL + "manufacturer/" + id + "/countries")
            .then(response => APIHelper.returnAttribute(response, 'countries'))
            .catch(APIHelper.returnFalse);
    },
    update(id, changes) {
        return axios
            .put(Config.API_URL + "manufacturer/" + id, changes)
            .then(APIHelper.returnPayload)
            .catch(APIHelper.returnFalse);
    },
    get(id) {
        return axios
            .get(Config.API_URL + "manufacturer/" + id)
            .then(APIHelper.returnData)
            .catch(APIHelper.returnFalse);
    }

};

export default ManufacturerService;
