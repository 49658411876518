import React, { createRef } from "react";
import PropTypes from "prop-types";
import ManufacturerService from "../services/ManufacturerService";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

/**
 * Typeahead component for searching for Brands
 * @component
 */
class ManufacturerSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            selectedIds: [],
        }
        this.ref = createRef();
    }

    handleSearch = (query) => {
        this.setState({
            isLoading: true,
        });

        ManufacturerService.typeahead(query, this.props.brands).then((options) => {
            if (options === null) {
                // this request was canceled due to a change in the typeahead component
                return;
            }
            this.setState({
                options: this.props.multiple
                    ? options.filter((option) => this.state.selectedIds.indexOf(option.id) === -1)
                    : options,
                isLoading: false,
            });
        });
    };

    /**
     * AsyncTypeahead seems to have a bug which is allowing the same option to be selected multiple times
     * so we need to keep track of the selected values uppon selection to get ride of the duplicates
     */
    handleOnChange = (selected) => {
        this.setState({
            selectedIds: selected.map((option) => option.id),
            options: [],
        }, () => {
            this.props.onChange(selected);
        });
    }

    clear = () => {
        this.ref.current.clear();
        this.setState({ selectedIds: [] });
    }

    render() {
        const filterBy = () => true;
        const onChange = this.props.multiple
                ? this.handleOnChange
                : this.props.onChange;

        return <AsyncTypeahead
            {...this.state}
            ref={this.ref}
            filterBy={filterBy}
            labelKey="name"
            id="manufacturer-search"
            maxResults={10}
            minLength={2}
            disabled={this.props.disabled}
            multiple={this.props.multiple}
            onSearch={this.handleSearch}
            onChange={onChange}
            defaultSelected={this.props.defaultSelected}
            placeholder="Search for a manufacturer..."
            renderMenuItemChildren={(option) => (
                <div
                    key={`m${option.id}`}
                >
                    <span className="colHead">id:</span>
                    <span className="colValue">
                        {option.id}
                    </span>
                    <span className="colHead">name:</span>
                    <span className="colValue">
                        {option.name}
                    </span>
                    <span className="colHead">categories:</span>
                    <span className="colValue">
                        {option.categories.map((category) => category.name).join(', ')}
                    </span>
                </div>
            )}
            useCache={false}
        />
    }
}

ManufacturerSearch.propTypes = {
    /**  */
    onChange: PropTypes.func.isRequired,
    multiple: PropTypes.bool,
    brands: PropTypes.array,
    disabled: PropTypes.bool,
};

ManufacturerSearch.defaultProps = {
    onChange: (values) => {
        console.log("Manufacturer has changed", values);
    },
    multiple: false,
    brands: [],
    disabled: false,
};

export default ManufacturerSearch;
