import axios from "axios";

import Config from "./Config";
import APIHelper from "./APIHelper";
import Utils from "./Utils";

const BrandProductsMappingService = {
    getCountries(brands, manufacturers) {
        const brandIds = brands.length > 0 ? brands : undefined;
        const manufacturerIds = manufacturers.length > 0 ? manufacturers : undefined;

        return axios
            .get(Config.API_URL + "regions", {
                params: {
                    brands: brandIds,
                    manufacturers: manufacturerIds
                }
            })
            .then(response => APIHelper.returnAttribute(response, 'data'))
            .catch(APIHelper.returnFalse);
    },

    getWebsites(requestBody) {
        return axios
            .post(Config.API_URL + "websites", requestBody)
            .then(response => APIHelper.returnAttribute(response, 'data'))
            .catch(APIHelper.returnFalse);
    },

    generateReport(requestBody) {
        const downloadContentTypes = ['application/force-download', 'application/octet-stream', 'application/download'];

        return axios
            .post(Config.API_URL + "diags/mapping", requestBody, { responseType: 'arraybuffer' })
            .then((response) => {
                const contentType = response.headers['content-type'];
                if (downloadContentTypes.includes(contentType)) {
                    const contentDisp = response.headers['content-disposition'];
                    const filename = contentDisp ? contentDisp
                        .split(';')[1]
                        .split('=')[1]
                        .replace('"', '')
                        .replace('"', '') : 'mapping_report.zip';

                    Utils.DownloadContent(filename, response.data);
                    return true;
                }
                return false;
            })
            .catch(APIHelper.returnFalse);
    }
};

export default BrandProductsMappingService;
