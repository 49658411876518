export const PERMISSION_TYPE = {
    ADMIN: 'admin',
    COSTS: 'costs',
    LOGS: 'logs',
    NPD: 'npd',
    PDD: 'pdd',
}

const ALL_PERMISSIONS = [
    PERMISSION_TYPE.ADMIN,
    PERMISSION_TYPE.COSTS,
    PERMISSION_TYPE.LOGS,
    PERMISSION_TYPE.NPD,
    PERMISSION_TYPE.PDD,
];

export const PERMISSIONS = {
    'agustina.beltramino': [
        PERMISSION_TYPE.COSTS,
    ],
    'alexander': ALL_PERMISSIONS,
    'andrew': [
        PERMISSION_TYPE.COSTS,
        PERMISSION_TYPE.LOGS,
    ],
    'ben.konides': [
        PERMISSION_TYPE.COSTS,
        PERMISSION_TYPE.LOGS,
        PERMISSION_TYPE.PDD,
    ],
    'catalin.bozianu': [
        PERMISSION_TYPE.COSTS,
        PERMISSION_TYPE.LOGS,
    ],
    'clien.abellanosa': [
        PERMISSION_TYPE.COSTS
    ],
    'cynthia.tsai': [
        PERMISSION_TYPE.PDD,
    ],
    'florencia.zaccaria': [
        PERMISSION_TYPE.COSTS,
        PERMISSION_TYPE.LOGS,
    ],
    'igor.gabelev': [
        PERMISSION_TYPE.COSTS,
        PERMISSION_TYPE.LOGS,
        PERMISSION_TYPE.NPD,
        PERMISSION_TYPE.PDD,
    ],
    'jose.gratereux': ALL_PERMISSIONS,
    'juan.gomez': [
        PERMISSION_TYPE.COSTS,
        PERMISSION_TYPE.LOGS,
    ],
    'julie.engel': ALL_PERMISSIONS,
    'leon.dore': ALL_PERMISSIONS,
    'leonardo.maia': ALL_PERMISSIONS,
    'mackenzie.flynn': [
        PERMISSION_TYPE.ADMIN,
        PERMISSION_TYPE.LOGS,
        PERMISSION_TYPE.PDD,
    ],
    'maria.barberan': [
        PERMISSION_TYPE.COSTS,
    ],
    'mario.figueroa': [
        PERMISSION_TYPE.NPD,
    ],
    'minerva.mada': [
        PERMISSION_TYPE.COSTS,
        PERMISSION_TYPE.LOGS,
    ],
    'michelle.dailing': [
        PERMISSION_TYPE.PDD,
    ],
    'neto': ALL_PERMISSIONS,
    'paula.falomir': [
        PERMISSION_TYPE.COSTS,
        PERMISSION_TYPE.NPD,
    ],
    'rene.manqueros': ALL_PERMISSIONS,
    'rizza.joseph': [
        PERMISSION_TYPE.COSTS,
    ],
    'samuel.ocampo': ALL_PERMISSIONS,
    'sean.mcelroy': [
        PERMISSION_TYPE.COSTS,
    ],
    'sonam.virk': [
        PERMISSION_TYPE.ADMIN,
        PERMISSION_TYPE.COSTS,
        PERMISSION_TYPE.LOGS,
        PERMISSION_TYPE.PDD,
    ],
    'tina': [
        PERMISSION_TYPE.COSTS,
        PERMISSION_TYPE.LOGS,
        PERMISSION_TYPE.PDD,
    ],
    'todd': [
        PERMISSION_TYPE.COSTS,
        PERMISSION_TYPE.PDD,
    ],
    'wendell': ALL_PERMISSIONS,
    'edson.silva': ALL_PERMISSIONS,
};
