import React from "react";
import './GsCountriesFilter.css'
import OnboardingService from "../services/OnboardingService";
import Toast from "../services/Toast";
import {Col, Row} from "react-bootstrap";
import MySpinner from "./MySpinner";

/**
 * Brands simple filter
 * @component
 */
class GsCountriesFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeId: 0,
            options: [],
            loading: false
        }
        this.renderFilter = this.renderFilter.bind(this);
    }

    componentDidMount() {
        this.setState({
            loading: true,
            activeId: this.props.countryId
        })

        OnboardingService.getCountriesList().then((data) => {
            this.setState({loading: false})

            if (data === false) {
                Toast.error("Something went wrong while loading countries");
                return;
            }

            this.setState({options: data.rows})
        })
    }

    onClick = (evt) => {
        const countryId = parseInt(evt.currentTarget.getAttribute('data-value'));
        this.setState({activeId: countryId})
        this.props.onChange(countryId);
    }

    renderFilter() {
        return  this.state.options.map((item) =>
            <div
                key={item.country_id}
                className={`btn-filter ${this.state.activeId === item.country_id ? "active" : ""}`}
                data-value={item.country_id}
                onClick={this.onClick}
            >
                <div className={`btn-label ${this.state.activeId === item.country_id ? "active" : ""}`}>{item.country_name}</div>
                <div className="btn-number">{item.total_websites}</div>
            </div>)
    }

    render() {
        return(
            <Row md={12} className="mb-3">
                <Col md={12}>Select a country:</Col>
                <Col md={12}>
                    {this.state.loading && <MySpinner />}
                    <div className="filters">
                        {this.renderFilter()}
                    </div>
                </Col>
            </Row>
        )
    }
}

export default GsCountriesFilter;
