import React from "react";
import { Breadcrumb, Container } from "react-bootstrap";
import { Redirect } from 'react-router';
import MySpinner from "../../../components/MySpinner";
import OnboardingService from "../../../services/OnboardingService";
import Toast from "../../../services/Toast";

class GoogleShoppingPD extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            websites: 0
        }
    }

    spinner() {
        return <div>
            <MySpinner
                style={{
                    position: "relative",
                    top: "5px",
                    marginRight: "10px",
                }} /> Checking for unidentified websites caught by Google Shopping Product Discovery crawlers ...
        </div>
    }

    componentDidMount() {
        OnboardingService.unidentified().then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while checking for unidentified websites.");
                this.setState({
                    loading: false,
                    websites: -1,
                    lastExecuted: false,
                });
                return;
            }

            if (data.rows.length === 0) {
                this.setState({
                    loading: false,
                    websites: 0,
                    lastExecuted: data.lastExecuted,
                });
                return;
            }

            this.setState({
                loading: false,
                websites: data.rows.length,
            });
        });
    }

    resultPage() {
        if (this.state.websites === 0) {
            return <div>
                <p>There are no new websites waiting to be checked.</p>
                <p><small>Last executed on {this.state.lastExecuted}</small></p>
            </div>;
        }
        if (this.state.lastExecuted === false) {
            return <Redirect push to="/onboarding/bucketization" />;
        }
        return <Redirect push to="/onboarding/bucketization/new" />;
    }

    render() {
        const page = this.state.loading
            ? this.spinner()
            : this.resultPage();

        return <Container className="pt-3">
            <Breadcrumb className="dd" style={{paddingLeft: "5px"}}>
                <Breadcrumb.Item>Onboarding</Breadcrumb.Item>
                <Breadcrumb.Item>Bucketization</Breadcrumb.Item>
                <Breadcrumb.Item active>Google Shopping PD</Breadcrumb.Item>
            </Breadcrumb>
            {page}
        </Container>
    }
}
export default GoogleShoppingPD;
